import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { HrserviceService } from "../service/hrservice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationDialogComponent } from "../../expense-manager/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-holiday-list",
  templateUrl: "./holiday-list.component.html",
  styleUrls: ["./holiday-list.component.css"],
})
export class HolidayListComponent implements OnInit {
  headingHoliday: string = "List of Holiday";
  p: number;
  holidayDate: Date;
  holidayname: string;
  holidaytype: string;
  selectedButton: string;
  selectedlist:string;
  holidaydata: any;
  formdata: any;
  holidayEventType:any;
  selectedEventType:string='offline';
  eventDescription: string = '';
   date:Date; 
   day:string; 
   month:string;
   year:Number ;
   formattedDate:String;
   currentDate:String;

  constructor(private hrservice: HrserviceService,private router: Router,
    private route: ActivatedRoute,public dialog: MatDialog) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.selectedButton = queryParams.selectedlist || 'all';
    
    });
    this.handleButtonClick(this.selectedButton);
  
  }
  _addHoliday(data: NgForm) {
    //console.log(data.value);
    this.date = data.value.holidaydate;
    this.day = String(this.date.getDate()).padStart(2, "0");
    this.month = String(this.date.getMonth() + 1).padStart(2, "0");
    this.year = this.date.getFullYear();
    this.formattedDate = `${this.year}-${this.month}-${this.day}`;
      //console.log(formattedDate);
      this.formdata = {
        date: this.formattedDate,
        name: data.value.holidayname,
        type: data.value.holidaytype,
        desc: data.value.eventdescription,
        location:data.value.eventlocation,
        status:data.value.eventtype,
        start_time:data.value.eventstarttime,
        end_time:data.value.eventendtime,
  
      };
    try {
      this.hrservice.addHolidayList(this.formdata).subscribe((res: any) => {
        alert(res.message);
         this.ngOnInit();
      });
    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
  }

  openDeleteConfirmation(holidayid:number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._deleteHoliday(holidayid )
      }
    });
  }

  _deleteHoliday(holidayid:number) {
    
    try {
      this.hrservice
        .deleteHolidayList(holidayid)
        .subscribe((res: any) => {
          // console.log(res.status);

          if (res.status) {

            alert(res.message);
            //window.location.reload();
            this.ngOnInit();
          }
        });
    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
  }
  resetForm(data: NgForm) {
    data.resetForm();
    this.selectedEventType = 'offline';
  }

  onButtonClick(button: string) {
   this.handleButtonClick(button);
  }

  private handleButtonClick(button:string) {
    switch (button) {
      case 'all':
        this._allHoliday(button);
        break;
      case 'upcoming':
        this._upcomingHoliday(button);
        break;
      case 'past':
        this._pastHoliday(button);
        break;
      default:
        this._allHoliday(button);
    }
  }
  _allHoliday(button:string) {
    this.p = 1;
    try {
      this.hrservice.holidayList().subscribe((res: any) => {
        console.log(res.data);
        this.holidaydata = res.data;
        this.selectedlist = button;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {selectedlist: button },
          queryParamsHandling: 'merge',
        });
      });
    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
    console.log("all holiday");
  }

  _upcomingHoliday(button:string) {
    this.p = 1;
    try {
      this.hrservice.holidayList().subscribe((res: any) => {
        this.selectedlist= button;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {selectedlist: button },
          queryParamsHandling: 'merge',
        });
        console.log(res.data);
        this.currentDate = new Date().toJSON().slice(0, 10);
        // console.log(this.currentDate);

        this.holidaydata = res.data.filter((res) => {
          console.log(res.date);
          const holidayDate = res.date;
          // console.log(holidayDate);
          return holidayDate >= this.currentDate;
        }).reverse();
        // console.log(this.holidaydata);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
  }

  _pastHoliday(button:string) {
    this.p = 1;
    try {
      this.hrservice.holidayList().subscribe((res: any) => {
        this.selectedlist= button;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { selectedlist: button },
          queryParamsHandling: 'merge',
        });
        console.log(res.data);
        this.currentDate = new Date().toJSON().slice(0, 10);
        // console.log(this.currentDate);

        this.holidaydata = res.data.filter((res) => {
          // console.log(res.date);
          const holidayDate = res.date;
          console.log(holidayDate);
          return holidayDate < this.currentDate;
        });
        // console.log(this.holidaydata);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
  }

  //  day to date function
  getDayForDate(date: string): string {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const [year, month, day] = date.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    const dayIndex = dateObject.getDay();
    return dayNames[dayIndex];
  }
}
