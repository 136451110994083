import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser/dist/app/PublicClientApplication';
import * as msal from 'msal';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private app: any;
    private config = {
        auth: {
            // clientId: 'e16b09b4-ef62-4403-8ccf-34c962de9209',
            clientId: 'e16b09b4-ef62-4403-8ccf-34c962de9209',
            authority: 'https://login.microsoftonline.com/7e38ac4f-7fbe-46ad-af19-8abc8322d9ed',
            redirectUri: 'https://staging-empower.amplussolar.com/',  // 'https://staging-empower.amplussolar.com/', 
            validateAuthority: true,
            cacheLocation: 'localStorage',
            postLogoutRedirectUri: 'https://staging-empower.amplussolar.com/' // 'https://empower.amplussolar.com/'
        }
    };

    constructor(private msalService: MsalService,
        private auth: AuthService,
        private router: Router) {
        this.app = new msal.UserAgentApplication(this.config)
    }


    login() {
        return this.app.loginPopup({
            scopes: ['user.read', 'offline_access']
        }).then((idToken: any) => {
            console.log('ID Token: ', idToken);
            localStorage.clear();
            this.getToken();
        }, (error: any) => {
            console.log(error);
        });
    }

    logout() {
        // var cookies = document.cookie.split(";");
        // console.log(document.cookie);
        // search for token expiration methodpol ikyfe
        this.app.logout();
        // const msal = new PublicClientApplication({
        //     auth: {
        //         clientId: "e16b09b4-ef62-4403-8ccf-34c962de9209"
        //     },
        //     system: {
        //         allowRedirectInIframe: true
        //     }
        // })

        // Automatically on page load
        // msal.logoutRedirect({
        //     onRedirectNavigate: () => {
        //         // Return false to stop navigation after local logout
        //         return false;
        //     }
        // });
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['login']);
    }

    isLoggedIn(): boolean {
        return localStorage.getItem('name') == null ? false : true;
    }

    getToken(): string {
        return this.app.acquireTokenSilent({
            scopes: ['1149c474-10c4-45d2-b504-3932c8d5306d/read'],
            // scopes: ['15abaa14-ea5b-4378-98f7-f549b524715d/read'], //offline_access
        }).then((accessToken: any) => {


            console.log("access token-->", accessToken);
            // this.accessToken(accessToken.accessToken);
            localStorage.setItem('accessToken', accessToken.accessToken);
            localStorage.setItem('name', accessToken.account.name);
            localStorage.setItem('userName', accessToken.account.userName);
            console.log(this.isLoggedIn());
            console.log(localStorage.getItem('accessToken'));
            this.router.navigate(['home']);
            // this.accessToken(accessToken.accessToken);
        }, (error: any) => {
            return this.app.acquireTokenPopup({
                scopes: ['1149c474-10c4-45d2-b504-3932c8d5306d/read'],
            }).then((accessToken: any) => {
                console.log("access error token-->", accessToken);
                // this.accessToken(accessToken.accessToken);
                localStorage.setItem('accessToken', accessToken.accessToken);
                localStorage.setItem('name', accessToken.account.name);
                localStorage.setItem('userName', accessToken.account.userName);
                console.log(this.isLoggedIn());
                console.log(localStorage.getItem('accessToken'));
                this.router.navigate(['home']);
            });
        });
    }

    // accessToken(data) {
    //     this.auth.getAccessToken(data).subscribe((res) => {
    //         console.log(res, "---------------auth result----------------")
    //         localStorage.setItem('accessToken', 'Token ' + res['token']);

    //         this.router.navigate(['home']);
    //     });

    // }
}