<div class="top_row">
  <div class="menu">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>

  <p>{{ employeeHeading }}</p>
  <p>{{ holidayHeading }}</p>
</div>
