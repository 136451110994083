import { Component, Inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmponboardingService } from '../services/emponboarding.service';

function amplussolarEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (email && !email.endsWith('@amplussolar.com')) {
      // If the email does not end with @amplussolar.com
      return { amplussolarEmail: true };
    }
    return null; // If the email is valid or the control is empty
  };
}

@Component({
  selector: 'app-add-to-emp',
  templateUrl: './add-to-emp.component.html',
  styleUrls: ['./add-to-emp.component.css']
})
export class EmpAddComponent implements OnInit {
  fb = new FormBuilder();
  departments: any;
  companies: any;
  managers: any;
  statuses: any;
  employmentTypes: any;
  hiringTypes: any;
  physicalLocations: any;
  businesses: any;
  umail: any;
  email: any;
  personalInfoForm: FormGroup;
  professionalInfoForm: FormGroup;
  initials = ['Mr', 'Ms', 'Mrs'];
  dialogRef: any;
  gotData: boolean = false;
  minDate :any;
  maxDate : any;
  on_roll_emp_id: any;
  off_roll_emp_id: any;
  jobTitles: any;
  transformedManagers: any;
  user_exists: boolean=true;
  emailErrorMessage: string | null = null;
  empIdErrorMessage: string | null = null;
  errorMessage: string | null = null;

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private empOnboardingService: EmponboardingService
  ) {

    this.personalInfoForm = this.fb.group({})
    this.professionalInfoForm = this.fb.group({
      employment_type: ['', Validators.required],
      emp_id: ['',[ Validators.required]],
      job_title: ['', Validators.required],
      functional_designation: [''],
      uan_number: [''],
      doj: [null, Validators.required],
      physical_location: ['', Validators.required],
      manager: [null, Validators.required],
      department: ['', Validators.required], //get from employee table
      functional_department: [''],
      business: ['', Validators.required],
      company: ['', Validators.required],
      hiring_type: ['', Validators.required],
      status: [0, Validators.required],
      email: ["", [Validators.required,amplussolarEmailValidator()]],
    })
    

  }
  
  
  ngOnInit() {
    this.professionalInfoForm.get('employment_type')?.valueChanges.subscribe((value) => {
      if(value === 'On-Roll'){
        this.professionalInfoForm.get('emp_id')?.setValue(this.on_roll_emp_id);
      } else {
        this.professionalInfoForm.get('emp_id')?.setValue(this.off_roll_emp_id);
      }
    });
    this.umail = this.route.snapshot.queryParamMap.get('umail');
    this.email = this.route.snapshot.queryParamMap.get('email');

    this.empOnboardingService.getEmpDefaultParams(this.email).subscribe((response: any) => {
      this.jobTitles = response.jobTitles;
      this.departments = response.depts;
      this.companies = response.companies;
      this.managers = response.managers;
      this.transformedManagers = this.managers.map((manager: any[]) => ({
        id: manager[0],
        name: manager[1]
      }));
    
      this.businesses = response.businesses;
      let year = parseInt(response.date.slice(0, 4));
      let month = (response.date.slice(5, 7))
      let day = (response.date.slice(8, 10))
      this.minDate = `${year-1}-${month}-${day}`
      this.on_roll_emp_id = response.on_roll_emp_id;
      this.off_roll_emp_id = response.off_roll_emp_id;
      this.maxDate = `${year+1}-${month}-${day}`      
      this.statuses = response.statuses;
      this.employmentTypes = response.employmentTypes;
      this.hiringTypes = response.hiringTypes;
      this.physicalLocations = response.physicalLocations;
      this.user_exists=response.user_exists;
      this.empOnboardingService.getProfile(this.umail, this.email).subscribe((response: any) => {
        // create controls in persolInfoForm for each key in response
        Object.keys(response).forEach((key) => {
          this.personalInfoForm.addControl(key, this.fb.control(null));
        });
        
        // this.personalInfoForm.get('emp_id')?.setValue();
        this.personalInfoForm.patchValue(response);
        this.personalInfoForm.disable();
        this.professionalInfoForm.get('emp_id')?.disable();
        this.gotData = true;
         (error: any) => {
          console.log(error);
        }
      })
    })
    

  }

  onSubmit() {
    this.professionalInfoForm.get('emp_id')?.enable();
    // console.log(this.professionalInfoForm.value)
    this.empOnboardingService.addEmp({email: this.email, 
      umail: this.umail, professionalDetails: this.professionalInfoForm.value})
    .subscribe((response: any) => {
      this.ngOnInit();
      console.log(response);
      (error: any) => {
        console.log(error);
      }
    },error => {
        // Handling email/emp_id errors
        if (error === 'ACTIVE_EMAIL_EXISTS') {
          this.emailErrorMessage = 'Active Employee with given email already exists';
        } else if (error === 'EMP_ID_EXISTS') {
          this.empIdErrorMessage = 'Employee ID already exists';
        }  
        this.errorMessage = 'An unexpected error occurred.';
    });
  }
}

