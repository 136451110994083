<!-- <div class="top_row_heading" *ngIf="lndUserType">
  <div
    class="col-1 header"
    (click)="allLink.home()"
    style="max-width: fit-content; cursor: pointer"
  >
    <i class="fa fa-chevron-left" aria-hidden="true"></i>
  </div>
  <p>HR Manager</p>
  <span class="nav_style" (click)="allLink.openNav()">&#9776; </span>
</div> -->
<span *ngIf="lndUserType">
<app-header-global></app-header-global>
</span>
<!-- <app-side-overlay-menu></app-side-overlay-menu> -->
<div class="row row_nav">
  <div class="col-sm-2 side_nav">
    <ul>
      <li>
        <a routerLink="employelist" routerLinkActive="active">Employee List</a>
      </li>
      <li>
        <a routerLink="holidaylist" routerLinkActive="active"
          >List of Holiday</a
        >
      </li>
      <!-- <li>
        <a routerLink="leavesreport" routerLinkActive="active">Leaves Report</a>
      </li> -->
    </ul>
  </div>
  <div class="col-sm-10">
    <router-outlet></router-outlet>
  </div>
</div>
